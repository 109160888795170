<template>
  <div class="helpCenterPage">
    <div class="content_area">
      <p class="pageTitle">{{ mainVideoTitle }}</p>
      <div class="mainVideo">
        <AliyunVideo
          :AliyunId="mainVideoId"
          class="mainVideoContent"
          :autoplay="false"
        ></AliyunVideo>
      </div>
      <div class="pageCenterDriver" v-if="videoList.length">
        <p class="pagesumTitle">
          {{ pageSumTitle }}
          <span>{{ pageSumTitleSmall }}</span>
        </p>
      </div>
      <div class="videoList clearfix">
        <div
          class="video_item fl"
          v-for="(item, index) in videoList"
          :key="index"
          @click="playVideo(item.aliyun_id)"
        >
          <div class="imgPanel">
            <al-image :src="item.cover_img" fit="cover"></al-image>
          </div>
          <div class="textPanel textOverflow" :title="item.video_name">
            {{ item.video_name }}
          </div>
        </div>
      </div>
      <videoPlay ref="playVideo"></videoPlay>
    </div>
  </div>
</template>

<script>
import AliyunVideo from "~/baseComponents/AliyunVideo";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  components: { AliyunVideo, videoPlay },
  mounted() {
    this.mainVideoId = this[`mainVideoId_${this.LOCALE}`];
  },
  data() {
    return {
      mainVideoTitle: this.$t("quicklyKnowExpo"),
      mainVideoId: "",
      mainVideoId_en: "406acb6ed85d4a24a8d8e3e25d4f65a8",
      mainVideoId_zh: "78936eea694f41c68beae40ee15df9b0",
      pageSumTitle: this.$t("playroundExpo"),
      pageSumTitleSmall: this.$t("updateanytime"),
      videoList: [
        {
          aliyun_id: "f2910721892d4bcea23058547dab36f4",
          cover_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/helpCenter/1v1.png",
          video_name: "如何进行1v1洽谈",
        },
         {
          aliyun_id: "867e8924c94b4dd3a837912f4089f4f1",
          cover_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/helpCenter/live.png",
          video_name: "如何创建直播活动",
        },
         {
          aliyun_id: "9a8d2507765b4e079e4bea1e47026781",
          cover_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/helpCenter/redpack.jpg",
          video_name: "如何发布业务红包",
        },
         {
          aliyun_id: "61f4a17815e8444da719f1b45e5916ea",
          cover_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/helpCenter/shiming.png",
          video_name: "如何进行实名认证",
        },
      ],
    };
  },
  methods: {
    playVideo(id) {
      this.$refs.playVideo.startPlay(id);
    },
  },
};
</script>

<style scoped lang="less">
.helpCenterPage {
  padding: 30px 0px;
  background: #f6f7f9;
  .pageTitle {
    font-size: 24px;
    font-weight: 800;
    color: #333333;
    line-height: 33px;
    margin-bottom: 16px;
  }
  .mainVideo {
    width: 1300px;
    height: 614px;
    border: 1px solid #e9e9e9;
  }
  .pagesumTitle {
    font-size: 18px;
    font-weight: 500;
    color: #1f292e;
    margin-top: 35px;
    margin-bottom: 32px;
    span {
      position: relative;
      top: 2px;
      font-size: 14px;
      color: #999999;
    }
  }
  .videoList {
    .video_item {
      margin-right: 20px;
      margin-bottom: 16px;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0px;
      }
      .imgPanel {
        width: 310px;
        height: 175px;
      }
      .textPanel {
        width: 310px;
        height: 52px;
        line-height: 52px;
        border: 1px solid #e9e9e9;
        padding-left: 16px;
      }
    }
  }
}
</style>